// index.tsx
import React, { useState } from "react";
import "./styles.scss";

const StickyMessage: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="sticky-message">
      <div className="message-content">
        <h3 className="message-title">Scheduled Maintenance Notice</h3>
        <p className="message-body">
          We are performing major infrastructure upgrades to enhance the performance of our services. Maintenance will take place during the next 24 hours. While we’ll work to minimize downtime, brief interruptions may occur, and some services might take an additional day to be fully restored.
        </p>
        <p className="message-signoff">Thank you for your patience and understanding as we improve your experience.<br />
        — The Hexact Team</p>
      </div>
      <button className="close-button" onClick={handleClose} aria-label="Close">&times;</button>
    </div>
  );
};

export default StickyMessage;